:root {
  --primary-color: #2389b5; /* Slightly lighter blue, matching the header gradient */
  --secondary-color: #34495e; /* Darker blue for contrast */
  --background-color: #ffffff; /* Pure white background */
  --text-color: #333333; /* Darker text for better readability */
  --header-bg: #ffffff; /* White header background */
  --accent-color: #ff6b6b; /* Coral accent color, complementing the blue and white */
  --light-accent: #f4f4f4; /* Light grey for subtle backgrounds */
}

/* Old :root values
:root {
  --primary-color: #1a5f7a;
  --secondary-color: #2c3e50;
  --background-color: #f8f9fa;
  --text-color: #000000;
  --header-bg: #ffffff;
  --accent-color: #e74c3c;
  --light-accent: #ecf0f1;
}
*/

body {
  font-family: 'Poppins', 'Roboto', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

/* Old font stack:
body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  ...
}
*/

.container {
  width: 90%;
  max-width: 1200px; /* Adjusted to match header max-width */
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3 {
  color: var(--primary-color);
  font-weight: 700;
}

h1 { font-size: 2.5rem; margin-bottom: 0.5em; }
h2 { font-size: 2rem; margin-bottom: 0.5em; }
h3 { font-size: 1.5rem; margin-bottom: 0.5em; }

p {
  margin-bottom: 1em;
}

button, .button {
  font-size: 1rem;
  font-weight: 600;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--accent-color);
  color: white;
  text-decoration: none;
  display: inline-block;
}

button:hover, .button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-2px);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--accent-color);
}

.section {
  padding: 4rem 0;
}

.section-light {
  background-color: var(--light-accent);
}

@media (max-width: 768px) {
  .container {
    width: 95%;
  }
  h1 { font-size: 2rem; }
  h2 { font-size: 1.75rem; }
  h3 { font-size: 1.25rem; }
}