:root {
    --footer-spacing: 60px;
    --min-content-height: 500px;
  }
  
  .layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    flex: 1;
    padding-top: var(--footer-spacing);
    padding-bottom: var(--footer-spacing);
    min-height: var(--min-content-height);
  }