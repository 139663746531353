.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  color: var(--primary-color);
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.sticky-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
}

nav ul li a:hover,
nav ul li a.active {
  color: var(--primary-color);
}

/* Underline effect on hover and active */
nav ul li a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--primary-color);
  transition: width .3s;
}

nav ul li a:hover::after,
nav ul li a.active::after {
  width: 100%;
}

/* Styles for signup and login buttons */
.header-auth-button {
  background-color: #1a365d;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.header-auth-button:hover {
  background-color: #2c5282;
}

.header-auth-buttons {
  display: flex;
}

/* Style for the placeholder message */
.header-auth-message {
  font-size: 0.9rem;
  color: #666;
  font-style: italic;
}

@media (max-width: 768px) {
  .sticky-header {
    padding: 1rem;
  }
  
  .sticky-header .container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  nav {
    width: 100%;
  }
  
  nav ul {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    width: 100%;
  }
  
  nav ul li {
    margin: 10px 0;
    width: 100%;
  }
  
  .logo {
    margin-bottom: 1rem;
  }
  
  .header-auth-buttons {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  .header-auth-button {
    margin: 5px 0;
    width: 100%;
    text-align: center;
  }

  /* Adjust placeholder message for mobile */
  .header-auth-message {
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
}