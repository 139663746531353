.main-footer {
  background-color: var(--header-bg);
  color: var(--text-color);
  padding: 40px 0;
  margin-top: 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding: 0 20px;
}

.footer-section h3 {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-transform: none;
  letter-spacing: normal;
}

.footer-nav {
  display: flex;
  flex-direction: column;
}

.footer-nav a {
  color: var(--text-color);
  text-decoration: none;
  margin-bottom: 0.6rem;
  transition: color 0.3s ease;
  font-size: 0.9rem;
  font-weight: 400;
}

.footer-nav a:hover {
  color: var(--primary-color);
}

.newsletter-form {
  display: flex;
  margin-top: 1rem;
}

.newsletter-form input[type="email"] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--light-accent);
  border-radius: 4px 0 0 4px;
  font-size: 0.9rem;
}

.newsletter-form button {
  padding: 10px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  font-weight: 500;
}

.newsletter-form button:hover {
  background-color: var(--secondary-color);
}

.footer-bottom {
  max-width: 1200px;
  margin: 30px auto 0;
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid var(--light-accent);
  font-size: 0.8rem;
}

.social-links {
  margin-top: 1rem;
}

.social-links a {
  color: var(--text-color);
  text-decoration: none;
  margin: 0 10px;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .footer-content {
    grid-template-columns: 1fr;
  }
}